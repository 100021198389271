/*=============== REUSABLE CSS CLASSES ===============*/
.section{
  padding: 5.5rem 0 1rem;

  &__title, &__title-center{
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-2);
    line-height: 140%;

    &-center{
      text-align: center;
    }
  }
}

.container{
  max-width: 1300px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid{
  display: grid;
}

.main{
  position: relative;
  overflow: hidden; // For animation
}