/*=============== HOME ===============*/
.home {
  padding: 5.5rem 0 2rem;

  &__container {
    position: relative;
    row-gap: 2rem;
  }
  &__img {
    width: 400px;
    justify-self: center;
    display: block;
    margin: 0 auto;
  }
  &__title-img {
    margin-bottom: 20px;
  }
  &__title {
    font-size: var(--big-font-size);
    line-height: 140%;
    margin-bottom: var(--mb-1);
  }
  &__description {
    margin-bottom: var(--mb-2-5);
    margin-top: var(--mb-2);
  }
  &__social {
    position: absolute;
    top: 2rem;
    right: -1rem;
    display: grid;
    justify-items: center;
    row-gap: 3.5rem;

    &-follow {
      font-weight: var(--font-medium);
      font-size: var(--smaller-font-size);
      color: var(--first-color);
      position: relative;
      transform: rotate(90deg);

      &::after {
        content: '';
        position: absolute;
        width: 1rem;
        height: 2px;
        background-color: var(--first-color);
        right: -45%;
        top: 50%;
      }
    }
    &-links {
      display: inline-flex;
      flex-direction: column;
      row-gap: 0.25rem;
    }
    &-link {
      font-size: 1rem;
      color: var(--first-color);
      transition: 0.3s;

      &:hover {
        transform: translateX(0.25rem);
      }
    }
  }
}

.sales {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: fit-content;
  padding: 60px;

  * {
    color: #fff !important;
  }

  .button--link {
    background: #fff;
    color: #c12a26 !important;
    padding: 10px 40px;
    border-radius: 0.5rem;

    i {
      color: #c12a26 !important;
    }
  }

  &__title {
    font-size: 50px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 15px;
  }

  .expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
  }

  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }

  .expired-notice > p {
    font-size: 1.5rem;
  }

  .show-counter {
    padding: 1rem 8rem;
    margin: 0 auto;
    border: 2px dotted #fff;
    border-radius: 0.25rem;

    .text {
      letter-spacing: 5px;
      font-size: 1.5rem;
      animation-name: pulse;
      animation-delay: 1s;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding: 1rem;
    text-decoration: none;
    color: #000;
  }

  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .show-counter .countdown.danger {
    color: #ff0000;
  }

  .show-counter .countdown > p {
    margin: 0;
  }

  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.home__box {
  position: relative;
}

.black-friday {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  margin: 0 auto;
  // margin-top: -40px;

  .sales-box {
    background-color: unset !important;
  }

  * {
    color: #c12a26 !important;
  }

  .text {
    display: none;
  }

  .countdown {
    border: 2px solid #ffcc01;
    border-radius: 5px 5px 5px 5px;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem !important;
    width: 57px;
    display: block !important;
    margin: 0.1rem;
  }

  .show-counter,
  .sales {
    padding: 0 !important;

    .button--link,
    .button__icon {
      background-color: #c12a26;
      color: #fff !important;
      animation-name: pulse;
      animation-delay: 1s;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }
}
