.cart {
  padding: 0 10px;
  max-width: 600px;
  margin: 80px auto 0 auto;

  &__title {
    font-size: 1.2rem;
    color: #338dbc;
    padding: 10px 0;

    i {
      margin-right: 10px;
    }
    border-top: 1px solid #e6e6e6;
  }

  &__bill > * {
    padding: 0 10px;
  }

  &__bill {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-top: 1px solid #e6e6e6;

    &-img {
      max-width: 64px;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      padding: 5px;
    }

    &-title {
      font-size: 0.9rem;
      line-break: auto;
      color: black;
    }

    &-capacity {
      font-size: 0.7rem;
    }

    &:nth-child(2) {
      align-items: start;
      font-size: 13px;
    }
  }

  .form {
    margin: 15px 0;
  }

  .form-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }
  }

  .form-payment {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }
  }

  .btn-submit {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    background-color: #008cba;
    border-radius: 5px;
    width: 100%;
  }

  .form_gift {
    text-align: start;
    margin-bottom: 20px;

    * {
      color: black;
    }

    &-title {
      color: rgb(164, 9, 7);
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.submited {
  margin-top: 100px;
  text-align: center;
  min-height: 30vh;

  img {
    width: 100px;
    margin-top: 20px;
  }

  div {
    margin-top: 20px;
  }
}
