/*=============== SCROLL UP ===============*/
.scrollup{
  position: fixed;
  background: var(--first-color);
  right: 1rem;
  bottom: -30%;
  display: inline-flex;
  padding: .5rem;
  border-radius: .25rem;
  z-index: var(--z-tooltip);
  opacity: .8;
  transition: .4s;

  &:hover{
    background-color: var(--first-color);
    opacity: 1;
  }

  &__icon{
    font-size: 1rem;
    color: #FFF;
  }
}

/* Show Scroll Up*/
.show-scroll{
  bottom: 3rem;
}

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar{
  width: .6rem;
  background: hsl(var(--hue), 4%, 53%);

  &-thumb{
    background: hsl(var(--hue), 4%, 29%);
    border-radius: .5rem;
  }
}