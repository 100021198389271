/*=============== HEADER ===============*/
.header {
  width: 100%;
  background-color: var(--body-color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  transition: 0.4s; // For animation dark mode
}

/*=============== NAV ===============*/
.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo,
  &__toggle,
  &__close {
    color: var(--title-color);
  }
  &__logo {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -1px;
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition: 0.3s;
    font-size: 1.3rem;

    &:hover {
      color: var(--first-color);
    }

    &-icon {
      width: 120px;
      //height: 50px;
      color: var(--first-color);
    }
  }
  &__toggle {
    display: inline-flex;
    font-size: 1.25rem;
    cursor: pointer;
  }

  &__menu {
    @media screen and (max-width: 767px) {
      position: fixed;
      background-color: var(--container-color);
      width: 80%;
      height: 100%;
      top: 0;
      right: -100%;
      box-shadow: -2px 0 4px hsla(var(--hue), 24%, 15%, 0.1);
      padding: 4rem 0 0 3rem;
      border-radius: 1rem 0 0 1rem;
      transition: 0.3s;
      z-index: var(--z-fixed);
    }
  }
  &__close {
    font-size: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    cursor: pointer;
  }
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
  &__link {
    color: var(--title-color);
    // color: #fff;
    font-weight: var(--font-medium);

    &:hover {
      color: var(--first-color);
    }
  }

  form {
    color: #555;
    display: flex;
    padding: 2px;
    border: 1px solid currentColor;
    border-radius: 5px;
    margin-top: -7px;
    max-width: 170px;
  }

  input[type='search'] {
    background: transparent;
    margin: 0;
    padding: 5px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
    width: 80%;
  }

  input[type='search']::placeholder {
    color: #bbb;
  }

  button[type='submit'] {
    text-indent: -999px;
    overflow: hidden;
    width: 20%;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    cursor: pointer;
    opacity: 0.7;
  }

  button[type='submit']:hover {
    opacity: 1;
  }

  button[type='submit']:focus,
  input[type='search']:focus {
    box-shadow: 0 0 3px 0 #1183d6;
    border-color: #1183d6;
    outline: none;
  }

  .search {
    display: none;
  }

  .shopping-card {
    font-size: 1.3rem;
    cursor: pointer;
    padding: 0.25rem;
  }
}

/* Show menu */
.show-menu {
  right: 0;
}

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 4px hsla(var(--hue), 4%, 15%, 0.1);
  background-color: var(--body-color);

  .nav__link {
    color: var(--title-color);
  }
}

/* Active link */
.active-link {
  position: relative;
  color: var(--first-color);

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: var(--first-color);
  }
}
